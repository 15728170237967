<div class="bg-gray-900">
    <div class="mx-auto max-w-7xl py-6 sm:px-6 sm:py-32 lg:px-8">
      <div class="bg-gray-900 px-6 pb-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
        <div class="w-full flex justify-center items-center">
            <img class="w-1/4" src="danglezone-logo.png"/>
        </div>
        <h2 class="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">Coming Soon</h2>
        <h4 class="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-300">Your personal zone for stick-handling greatness.</h4>
        <p class="mx-auto mt-6 max-w-xl text-pretty text-md text-gray-300">Join our community</p>
        <div class="w-96 mx-auto">
            <label for="query" class="sr-only block text-sm/6 font-medium text-gray-900">Email</label>
            <div class="mt-2 flex">
              <div class="-mr-px grid grow grid-cols-1 focus-within:relative">
                <input type="email" name="email" class="col-start-1 row-start-1 block w-full rounded-l-md bg-white py-1.5 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 pl-3 sm:text-sm/6" placeholder="you@example.com">
              </div>
              <button type="button" class="flex shrink-0 items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 hover:bg-gray-50 focus:relative focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600">
                <!-- <svg class="-ml-0.5 size-4 text-gray-400" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M2 2.75A.75.75 0 0 1 2.75 2h9.5a.75.75 0 0 1 0 1.5h-9.5A.75.75 0 0 1 2 2.75ZM2 6.25a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5A.75.75 0 0 1 2 6.25Zm0 3.5A.75.75 0 0 1 2.75 9h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 2 9.75ZM9.22 9.53a.75.75 0 0 1 0-1.06l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1-1.06 1.06l-.97-.97v5.69a.75.75 0 0 1-1.5 0V8.56l-.97.97a.75.75 0 0 1-1.06 0Z" clip-rule="evenodd" />
                </svg> -->
                Submit
              </button>
            </div>
        </div>
      </div>
    </div>
</div>
  
<router-outlet></router-outlet>
